.answer {
    margin-bottom: 10px;

    .question-text {
        white-space: pre-wrap;
        min-height: 100px;

        .num {
            font-size: 16px;
            color: #bbd0e2;
            float: right;
        }
    }

    .answer-text {
        min-height: 100px;

        .slow {
            color: blue
        }
        .norm {
            color: green
        }
        .fast {
            color: red
        }
    }

    .toolbar {
        background-color: #f5f5f5;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        padding: 10px 10px 10px 15px;

        .answer_time {
            padding: 0 10px;
        }
    }

    .graph {
        width: 300px;
        height: 200px;
        display: inline-block;
    }
}
