.test-view {
    .question {
        white-space: pre-wrap;
        background-color: #f5f5f5;
        border: 1px solid #ccc;
        border-radius: 4px;
        color: #333;
        margin: 0 0 10px;
        padding: 9.5px;
    }
}